'use strict';

angular.module('enervexSalesappApp').controller('CustomerCompanyCtrl', function($scope, CustomerCompany, $stateParams, Flash, _) {
	$scope.errors = {};

	$scope.company = null;

	Flash.clear();

	function fetchCompany() {
		if ($stateParams.id == "new") {
			$scope.company = {
				"ID": null,
				"Created": new Date(),
				"Revised": new Date(),
				"OldID": "",
				"AccountNo": "",
				"Company": "",
				"Address1": "",
				"Address2": "",
				"City": "",
				"State": "",
				"Zip": "",
				"Country": "",
				"Phone": "",
				"Fax": "                                                  ",
				"SellToCompany": "",
				"EXHsales": "",
				"SalesChamp": "",
				"AccountType": "REP",
				"Office": "",
				"Status": "ENG",
				"Website": "                                                  ",
				"HeatZone": "",
				"SalesBudget": 0,
				"InstBudget": 0,
				"QuoteBudget": 0,
				"JobsBudget": 0,
				"Multiplier": 0.4099999964237213,
				"Category": "",
				"Access": "",
				"Blocked": false
			}
		} else {
			CustomerCompany.get({
				id: $stateParams.id
			}).$promise.then(function(company) {
				$scope.company = company;
			});
		}
	}
	fetchCompany();
	$scope.save = function(form){
		// var payload = _.omit($scope.user, "company");
		// payload.RepCo = $scope.user.company.ID;
		var payload = $scope.company;
		payload = _.omit(payload, "__v");
		if (form.$valid){
			if (!payload.ID) {
				payload.ID = UUIDjs.create().toString()
				CustomerCompany.save({}, payload).$promise.then(function(res){
					Flash.create('info', "successfully saved", 0, {}, true);
				}).catch(function(err){
					$scope.errors.other = err.message;
					var message = err.message;
					Flash.create('warning', message, 0, {}, true);
				});
			} else {
				CustomerCompany.update({
					id: payload.ID
				}, payload).$promise.then(function(res){
					Flash.create('info', "successfully saved", 0, {}, true);
				}).catch(function(err){
					$scope.errors.other = err.message;
					var message = err.message;
					Flash.create('warning', message, 0, {}, true);
				});
			}
		}
	}
});
